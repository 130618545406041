<template>
	<div class="margin-t-20 width-fill">
		<div :class="themeClass" class="width-fill bg-fff my-account flex-row-space-between-center">
			<div class="title font-size18 font-weight700">资料变更记录</div>
			<div class="closeTtn" @click="closeTtn">取消</div>
		</div>
		<div class="tab-content">
			<el-table :loading="true" :data="tableData" stripe border :row-style="{ height: '36px' }" :header-cell-style="{ 'text-align': 'center', background: '#F2F2F2', 'font-weight': '400', color: '#000' }">
				<el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
				<el-table-column prop="fStatus" label="状态" width="100" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.fStatus == 0">初始</div>
						<div v-if="scope.row.fStatus == 1" style="color: #33CC66;">变更成功</div>
						<div v-if="scope.row.fStatus == 2">撤销</div>
					</template>
				</el-table-column>
				<el-table-column prop="fSourceAccountName" label="变更前信息" align="left" show-overflow-tooltip></el-table-column>
				<el-table-column prop="fAccountName" label="变更后信息" align="left" show-overflow-tooltip></el-table-column>
				<el-table-column prop="fCreator" label="申请人" width="150" align="left" show-overflow-tooltip></el-table-column>
				<el-table-column prop="fCreateTime" label="申请时间" width="150" align="left" show-overflow-tooltip></el-table-column>
				<el-table-column label="操作" width="100" align="center">
					<template slot-scope="scope">
					    <el-button
							v-show="scope.row.fStatus == 0"
							@click.native.prevent="revokeChange(scope.row)"
							type="text"
							size="small">
							撤销
					    </el-button>
						<el-button
							v-show="scope.row.fStatus == 0"
							@click.native.prevent="submitInfo(scope.row)"
							type="text"
							size="small">
							提交
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="flex-row-center-flex-end margin-t-20 margin-b-30"  >
				<pagination @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" :currentPage="currentPage"
				 :pagesize="pagesize" :total="total"></pagination>
			</div>
		</div>
		
	</div>

</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import pagination from "@/components/pagination/pagination.vue";
	export default {
		components: {
			pagination
		},
		data() {
			return {
				total: 0, //总条数
				pagesize: 15, //每页显示条目个数
				currentPage: 1, //当前页
				tableData: []
			};
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
			...mapGetters(['getUserInfo']),
		},
		mounted() {
			this.getList()
		},
		methods: {
			closeTtn() {
				this.$router.push({
					path: '/businessme/JYTwallet',
					query: {
						title: '交易通'
					}
				})
			},
			submitInfo(row) {
				this.$router.push({
					path: '/businessme/JYTUpload',
					query: {
						id: row.fBillID
					}
				})
			},
			//pageSize（每页个数） 改变时会触发
			handleSizeChange(val) {
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			// 获取变更列表
			getList() {
				this.ApiRequestPostNOMess('/api/mall/ebpayment/payment-jyt-account-change-bill/get-list', {
					skipCount:(this.currentPage - 1) * this.pagesize,
					maxResultCount: this.pagesize,
					filter: "",
					sorting: ""
				 }).then(
				  res => {
					res.obj.items.forEach(item => {
						item.fCreateTime = this.getTime(item.fCreateTime);
						item.fModifyTime = this.getTime(item.fModifyTime);
					})
					this.total = res.obj.totalCount;
					this.tableData = res.obj.items;
				  },
				  rej => { }
				);
			},
			// 撤销
			revokeChange(row) { 
				this.$confirm('确定要撤销吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.CustomerFun(row);
				}).catch(() => {
				
				});
				
			},
			CustomerFun(row) {
				this.ApiRequestPostNOMess('/api/mall/ebpayment/payment-jyt-account-change-bill/revoke-change', {
					fBillID: row.fBillID,
					frv: row.frv,
					rowStatus: row.rowStatus
				 }).then(
				  res => {
					this.getList()
				  },
				  rej => { }
				);
			}
			
		}
	}
</script>

<style lang="scss" scoped>
	.closeTtn {
		text-align: center;
		border: 1px solid #0066FF;
		color: #0066FF;
		width: 98px;
		height: 32px;
		line-height: 32px;
		cursor: pointer;
	}
	.tab-content {
		width: 100%;
		margin-top: 20px;
	}
	
	/deep/ .el-table td {
		padding: 0;
	}
	
	/deep/ .el-table th {
		height: 36px;
		padding: 0;
	}
</style>
